<template>
    <div class="callback">
        <h1>Callbacking stuff..</h1>
    </div>
</template>

<script>
//import {handleAuth} from '../utils/auth'
export default {
    name: "Callback",
    beforeMount() {
        this.$auth.handleAuthentication().then((data) => {
            this.$router.push({ name: 'About' })
        })
    },
    props: {

    },
    methods: {
        
    },
}
</script>